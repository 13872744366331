import React from 'react'
import { Flex, Text } from '../../primitives'
import { HeadingMedium } from '../text/HeadingMedium'
import { TextWithClick } from '../text/TextWithHover'
export const Contact = () => {
  return (
    <Flex
      jusifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}>
      <HeadingMedium mt={4} mb={4} title={'Om oss'} />

      <Flex
        maxWidth={'1000px'}
        mt={4}
        mb={7}
        pr={2}
        pl={2}
        flexDirection={['column', 'row', 'row']}
        jusifyContent={'center'}
        width={'100%'}>
        <Flex flex={1} flexDirection={'column'}>
          <Text style={{ lineHeight: '1.5' }}>
            <b>Vad är Hemset? </b>
            <br />
            <br />
            Hemset är det nya sättet att inreda. Vi är en digital plattform där
            kunder och inredningsdesigners kopplas ihop online. Inredarna utför
            sitt jobb på annars outnyttjad tid och kan på så vis erbjuda sina
            tjänster till ett mer förmånligt pris. Vårt mål är att ändra
            synsättet på inredningshjälp eftersom vi starkt tror på att
            inredningstjänster ska vara tillgängliga för alla. <br />
            <br /> <b>Idén bakom inredningshjälp online</b> <br /> <br /> Idén
            bakom Hemset föddes ur vetskapen att inredningstjänster har många
            onödiga kostnader. När vi grävde djupare i de traditionella
            inredningstjänsterna fann vi också att inredningsdesigners har en
            generellt ojämn arbetsbelastning. Vi insåg att vi kunde digitalisera
            inredningstjänsten till att vara tillgänglig för alla genom att
            bygga en digital plattform. En plattform där inredningsdesigners kan
            registrera sig för jobb för att fylla ut sin tid. Därigenom hjälpa
            fler människor få ett mer trivsamt hem genom att kunderna själva
            enkelt laddar upp en film, sätter budget, beskriver sin stil och
            fyller i sin kontaktinformation. Istället för att spendera tid på
            att hitta kunder och göra hembesök kan våra inredningsdesigners
            uppnå en högre effektivitet. Kunderna i sin tur kan genom Hemset, få
            det hem de längtar efter till ett väldigt förmånligt pris.
          </Text>
        </Flex>
        <Flex
          mt={[4, 0, 0]}
          flex={1}
          flexDirection={'column'}
          alignItems={'center'}>
          <Text mb={3} fontSize={6}>
            Kontakta oss
          </Text>
          <Text mb={2}>+46103440073</Text>
          <a href='mailto:info@hemset.se' style={{ textDecoration: 'none' }}>
            <TextWithClick>info@hemset.se</TextWithClick>
          </a>
        </Flex>
      </Flex>
    </Flex>
  )
}
