import React, { useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { HeroAbout } from '../components/shareable/hero/HeroAbout'
import bg from '../images/about.jpg'
import { Contact } from '../components/shareable/sections/Contact'

export default ({ navigate }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      headTitle={'Om oss & vår inredningsidé | Hemset.se'}
      metaDesc={
        'Hemset är det nya sättet att inreda online eftersom vi starkt tror på att inredningstjänster ska vara tillgängliga för alla.'
      }
      blackNav={'true'}
      >
      <HeroAbout bgSrc={bg} height={'60vh'} />
      <Contact />
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
