import React from 'react'
import { Flex, ImageCover } from '../../primitives'

export const HeroAbout = ({ bgSrc, display }) => {
  return (
    <Flex
      display={display}
      style={{ position: 'relative' }}
      maxHeight={['725px', '725px', 'initial']}
      height={['80vh', '80vh', '550px']}
      minHeight={['650px', '650px', 'initial']}
      jusifyContent={'center'}
      alignItems={'center'}>
      <Flex
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 11
        }}>
        <ImageCover width={'100%'} height={'100%'} src={bgSrc}></ImageCover>
      </Flex>
      <Flex style={{ zIndex: 12 }} maxWidth={'1000px'}></Flex>
    </Flex>
  )
}
